import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PublishDate = makeShortcode("PublishDate");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A celebration of the pioneering marine gravity work of Dutch geophysicist
Professor F.A. Vening  Meinesz more than 80 years ago was recently held in
Delft. The symposium took place in the  Mekelzaal of the Science Centre in Delft
(space formerly occupied by the mineralogical museum)  and was organised by the
Hollandse Cirkel, an organisation dedicated to the history of geodesy in  The
Netherlands. `}</p>
    <p>{`In cooperation with the Royal Netherlands Navy, a journey was made by submarine
K-18 from  Holland to Indonesia in 1935 by way of Brazil, Cape Town, Mauritius
and Perth. Using his specially developed pendulum apparatus - displayed at the
meeting - Vening Meinesz made 260 gravity  measurements at a depth of 20 metres
below the sea-surface while 'silent cruising'. These  readings contributed
greatly to early knowledge of the variation of gravity with latitude and the
shape of the geoid. Most geophysicists will remember the large negative
anomalies south of  Indonesia discovered during this work which led eventually
to the identification of subduction  zones. Amongst the many speakers, including
some from the present-day Navy, Professor Ramon  Hanssen (Geodesy, TU Delft)
outlined the history of Dutch involvement with gravity meaurements,  from the
work of Corret de Groot (in 1586 - before the work of Galileo) to the
achievements of the  latest satellites of the European Space Agency that has its
scientific headquarters in Holland. It is  perhaps typical of this country and
its tendency always to keep a low profile that the geophysical  contribution of
Professor Vening Meinesz is better-known internationally than nationally.
Hopefully  the November 10 Symposium will help redress the balance. A model of
the submarine K-18 is  shown in the picture. `}</p>
    <PublishDate mdxType="PublishDate" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      